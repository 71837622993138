<div class="project-list-container margin-side-bar mt-20">
  @if (mode === ProjectListingMode.ARCHIVED) {
    <app-back-button [emitEvent]="true" (emitBackClick)="toggleListingMode()" />
  }
  <div
    class="header-container d-flex justify-content-space-between align-items-end title-container"
  >
    <div class="d-flex g-13">
      <app-page-title [title]="pageTitle" />
      @if (mode === ProjectListingMode.UNARCHIVE) {
        <p-button
          (click)="toggleListingMode()"
          (keyup.enter)="toggleListingMode()"
          class="view-archive-button"
          [text]="true"
          size="small"
          type="button"
          [style]="{ 'padding': '5px' }"
          tabindex="0"
        >
          <div class="d-flex g-8 align-items-center">
            <img src="assets/icons/archive-icon-samll.svg" alt="list" height="25px" />
            <app-page-title title="View Archive" [size]="14" />
          </div>
        </p-button>
      }
    </div>
    @if (mode === ProjectListingMode.UNARCHIVE) {
      <div *ngIf="hasCreateProjectPermission" class="d-flex align-items-center g-13 mt-20">
        <app-download-template-overlay [downloadTemplate]="downloadTemplate" />
        <div>
          <p-button
            class="primary-color upload-tasks"
            type="button"
            label=""
            [outlined]="true"
            size="small"
            (click)="fileInput.click()"
            (keyup.enter)="fileInput.click()"
            tabindex="0"
          >
            <div class="d-flex g-8 align-items-center">
              <img src="assets/icons/file-import.svg" alt="list" height="25px" />
              <app-page-title title="Upload Projects" [size]="14" />
            </div>
          </p-button>
          <input
            #fileInput
            type="file"
            (change)="onFileSelect($event)"
            accept=".xls,.xlsx"
            hidden
          />
        </div>
        <p-button
          class="primary-color add-project"
          type="button"
          icon="pi pi-plus"
          label="Add Project"
          [outlined]="true"
          size="small"
          (click)="addProject()"
          (keyup.enter)="addProject()"
          tabindex="0"
        />
      </div>
    }
  </div>
  <div *ngIf="hasViewProjectPermission">
    <app-table [tableConfig]="projectsTableConfig" (tableSignals)="handleTableSignals($event)">
      <ng-template #noRecordFoundTemplate>
        <div class="container">
          <div class="image-container">
            <img src="assets/icons/projects_empty_state.svg" alt="" />
          </div>
          <span class="empty-state-title">No Projects</span>
          <span class="empty-state-description">There are no projects listed</span>
          @if (hasCreateProjectPermission && mode === ProjectListingMode.UNARCHIVE) {
            <div class="box">
              <p-button
                class="primary-color add-task"
                type="button"
                class="add-task"
                icon="pi pi-plus"
                label="Add Project"
                [outlined]="false"
                size="small"
                (onClick)="addProject()"
              />
            </div>
          }
        </div>
      </ng-template>
    </app-table>
  </div>
</div>
